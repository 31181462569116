import React, { useEffect, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Interviews() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {}, [loading]);
  const videos = [
    "https://www.youtube.com/embed/7DFWMDzgko0",
    "https://www.youtube.com/embed/6wuWIq-_QN4",
    "https://www.youtube.com/embed/7hkil5GZ5Nc",
    "https://www.youtube.com/embed/J66yDLuhbrg",
    "https://www.youtube.com/embed/xBtgeSgWow4",
    "https://www.youtube.com/embed/zNaa-BEpakc",
  ];
  return (
    <div className="section is-medium " id="media">
      <div className="content">
        <h1 className="is-size-1 has-text-centered has-text-dark underline">
          <span>Media Presence</span>
        </h1>
      </div>

      <div className="columns is-multiline is-centered">
        {videos.map((v, index) => (
          <div className="column is-4" key={index}>
            {loading ? (
              <PropagateLoader
                className="loader-videos"
                color={"hsl(141, 95%, 49%)"}
              />
            ) : null}
            <figure className="image is-16by9" key={index}>
              <iframe
                className="has-ratio"
                width="640"
                height="360"
                src={v}
                frameBorder="0"
                title="Haymos"
                allowFullScreen={true}
                key={index}
                onLoad={() => setLoading(false)}
              ></iframe>
            </figure>
          </div>
        ))}
      </div>
    </div>
  );
}
