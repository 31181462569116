import React, { useEffect, useState } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import "react-slideshow-image/dist/styles.css";
import NavbarC from "./components/NavbarComponent";
import About from "./components/About";
import Interviews from "./components/Interviews";
import Specs from "./components/Specs";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import PropagateLoader from "react-spinners/PropagateLoader";
import ResponsiveCarousel from "./components/ResponsiveCarousel";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init(
  {
    offset:120,
    duration:3000,

  }
);

document.querySelectorAll('img')
.forEach((img) =>
img.addEventListener('load',()=>AOS.refresh()))

function App() {
  const logo = require("../src/components/logo.png");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <img
            className="block"
            data-aos="fade-down"
            src={logo}
            width="200"
            alt="Logo haymos"
          />
          <PropagateLoader
            size={20}
            color={"hsl(141, 95%, 49%)"}
            className="block"
          />
        </div>
      ) : (
        <div className="main">
          <NavbarC />
          <ResponsiveCarousel />
          <About />
          <Specs />
          <Gallery />
          <Interviews />
          <Contact />
          <Footer />
        </div>
      )}
    </>
  );
}
export default App;
