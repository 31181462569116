import React, { useState, useEffect } from "react";

import { Link } from "react-scroll";
import Hamburger from "hamburger-react";
const logo = require("./logo.png");

function toggleDropdown() {
  const myNav = document.querySelector("#my-nav");
  const burger = document.querySelector("#burger");
  myNav.classList.toggle("is-active");
  burger.classList.toggle("is-active");
}
function NavbarComponent() {
  const [navColor, setnavColor] = useState("rgba(0, 0, 0, 0.388)");
  const listenScrollEvent = () => {
    window.scrollY > 50
      ? setnavColor("rgba(0, 0, 0, 0.8)")
      : setnavColor("rgba(0, 0, 0, 0.388)");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <nav
      style={{
        backgroundColor: navColor,
        transition: "all 1s",
      }}
      className="navbar  is-fixed-top has-shadow "
      role="navigation"
      aria-label="main navigation"
    >
      <div
        className="navbar-brand"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.38)" }}
      >
        <Link smooth spy to="home">
          <img className=" ml-6 mt-2 " src={logo} width="80" alt="Logo haymos" />
        </Link>

        <span
          id="burger"
          className="navbar-burger mr-5 mt-3"
          onClick={toggleDropdown}
        >
          <Hamburger color="hsl(141, 95%, 49%)" />
        </span>
      </div>
      <div id="my-nav" className="navbar-menu">
        <div className="navbar-end mt-2 mr-6 ml-6">
          <Link
            activeClass="active"
            smooth
            spy
            to="home"
            className="navbar-item"
          >
            Home
          </Link>
          <Link
            activeClass="active"
            smooth
            spy
            to="about"
            className="navbar-item"
          >
            About
          </Link>
          <Link
            activeClass="active"
            smooth
            spy
            to="specs"
            className="navbar-item"
          >
            Specs
          </Link>
          <Link
            activeClass="active"
            smooth
            spy
            to="gallery"
            className="navbar-item"
          >
            Gallery
          </Link>
          <Link
            activeClass="active"
            smooth
            spy
            to="media"
            className="navbar-item"
          >
            Media
          </Link>
          <Link
            activeClass="active"
            smooth
            spy
            to="contact"
            className="navbar-item"
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
}
export default NavbarComponent;
