import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function About() {

  const aboutPic = require("./images/img/aboutpic.JPG");
  const hay = require("./images/img/photoH.JPG");
  const mo = require("./images/img/photoM.JPG");
  const s = require("./images/img/photoS.JPG");
  const h1 = require("./images/placeholderImg/holder1.JPG");
  const h2 = require("./images/placeholderImg/holderh.JPG");
  const h3 = require("./images/placeholderImg/holderm.JPG");
  const h4 = require("./images/placeholderImg/holders.JPG");
  return (
    <section id="about" className="is-large">
      <div className="section is-medium">
        <div className="content">
          <h1 className="is-size-1 has-text-centered has-text-dark underline">
            <span> About Us</span>{" "}
          </h1>
        </div>
        <div className="columns">
          <div className="column " data-aos="fade-right">
            <LazyLoadImage
              className="image is-256x256"
              src={aboutPic}
              alt="Haymos Tunisie"
              placeholderSrc={h1}
              effect="blur"
            />
          </div>
          <div className="column">
            <div
              className="content is-large has-text-justified"
              data-aos="fade-left"
            >
              <ul>
                <li>
                  Since 2015 we dreamed of making this buggy, until the year
                  2017 when Hayder proposed to start the work to make this dream
                  a reality.
                </li>
                <br />
                <li>
                  Haymos is a buggy prototype designed for rural areas made in
                  2021 by 3 Tunisian brothers it is a self-financing project.
                </li>
                <br />
                <li>
                  Haymos has been designed with performance in mind and features
                  a stiff tubular frame, double independent suspension.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="hero is-small is-light">
        <div className="content">
          <h1 className="is-size-1 has-text-centered has-text-dark underline">
            <span>Team Membres</span>
          </h1>
        </div>
        <div className="hero-body">
          <div className="columns">
            <div className="column">
              <div className="card" data-aos="fade-up">
                <div className="card-content">
                  <div className="content">
                    <p className="title is-4">Hayder Khefifi</p>
                  </div>
                  <div className="content">
                    <p className="subtitle is-size-5">
                      Head Of Audit Department
                    </p>
                  </div>
                </div>
                <div className="card-image">
                  <LazyLoadImage
                    src={hay}
                    alt="Hayder Khefifi"
                    placeholderSrc={h2}
                    effect="blur"
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card" data-aos="fade-down">
                <div className="card-image">
                  <LazyLoadImage
                    src={mo}
                    alt="Montassar Khefifi"
                    placeholderSrc={h3}
                    effect="blur"
                  />
                </div>
                <div className="card-content">
                  <div className="content">
                    <p className="title is-4">Montassar Khefifi</p>
                  </div>
                  <div className="content">
                    <p className="subtitle is-size-5"> Data Science Student</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card" data-aos="fade-up" >
                <div className="card-content">
                  <div className="content">
                    <p className="title is-4">Selim Khefifi</p>
                  </div>
                  <div className="content">
                    <p className="subtitle is-size-5">Developer Student</p>
                  </div>
                </div>
                <div className="card-image">
                  <LazyLoadImage
                    src={s}
                    alt="Selim Khefifi"
                    placeholderSrc={h4}
                    effect="blur"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
