import React from "react";

export default function Specs() {
  return (
    <section className="section is-medium" id="specs">
      <div className="content">
        <h1 className="is-size-1 has-text-centered has-text-dark underline">
          <span> Prototype Specs</span>
        </h1>
      </div>
      <div className="columns is-multiline" data-aos="zoom-out-down">
        <div className="column is-half">
          <table className="table table is-fullwidth">
            <thead>
              <tr>
                <th colSpan={2}>
                  <abbr title="structure">STRUCTURE</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="has-text-weight-semibold">TYPE</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  Buggy
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold">NUMBER OF PLACES</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  2
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold">CHASSIS</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  Rectangular Steel
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column is-half">
          <table className="table table is-fullwidth">
            <thead>
              <tr>
                <th colSpan={2}>
                  <abbr title="Motorisation">ENGINE</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="has-text-weight-semibold">DISPLACEMENT</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  4 cylinders 1000 cm3
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold"> FISCAL POWER</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  5 CH
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold">FUEL</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  Petrol
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column is-half">
          <table className="table table is-fullwidth">
            <thead>
              <tr>
                <th colSpan={2}>
                  <abbr title="Dimensions">DIMENSIONS</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="has-text-weight-semibold">L * W * H</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  305 * 163 * 180 CM
                </td>
              </tr>

              <tr>
                <td className="has-text-weight-semibold">WHEELBASE</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  230 CM
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column is-half">
          <table className="table table is-fullwidth">
            <thead>
              <tr>
                <th colSpan={2}>
                  <abbr title="Suspension">SUSPENSION</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="has-text-weight-semibold">
                  SUSPENSION FRONT/REAR
                </td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  Independent, Double A-arm{" "}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold">Ground Clearance</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  45 CM
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column is-half">
          <table className="table table is-fullwidth">
            <thead>
              <tr>
                <th colSpan={2}>
                  <abbr title="Transmision">TRANSMISION</abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="has-text-weight-semibold">GEARBOX</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  Manuelle
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold">NUMBER OF REPORTS</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  5
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column is-half">
          <table className="table table is-fullwidth">
            <thead>
              <tr>
                <th colSpan={2}>
                  <abbr title="Poids">POIDS </abbr>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="has-text-weight-semibold">DRY WEIGHT</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  750 Kg
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-semibold">LOAD CAPACITY</td>
                <td className="has-text-grey" style={{ textAlign: "end" }}>
                  500 Kg
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
