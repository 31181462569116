import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function ResponsiveCarousel() {
  const s1 = require("./images/img/haymos1.JPG");
  const s3 = require("./images/img/haymos.jpg");
  const s4 = require("./images/img/haymos4.JPG");
  const h1 = require("./images/placeholderImg/holder1.JPG");
  const h3 = require("./images/placeholderImg/haymosh.jpg");
  const h4 = require("./images/placeholderImg/placeholder4.JPG");

  const images = [
    [s4, h4],
    [s1, h1],
    [s3, h3],
  ];

  return (
    <section className="section is-small carousel-container" id="home">
      <Carousel
        showArrows={true}
        autoPlay
        showThumbs={false}
        stopOnHover={false}
        showStatus={false}
        infiniteLoop={true}
        width={"100%"}
      >
        {images.map((s, index) => (
          <div key={index}>
            <LazyLoadImage
              width={"100%"}
              src={s[0]}
              alt="Haymos Voiture Tunisie"
              placeholderSrc={s[1]}
              style={{
                boxShadow: "15px 15px 20px 0 rgba(255, 255, 255, 0.525)",
              }}
              key={index}
              effect="blur"
            />
          </div>
        ))}
      </Carousel>
    </section>
  );
}
