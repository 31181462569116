import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
export default function Contact() {
  AOS.refresh()
  const initialValues = { user_email: "", message: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setErrors(validate(formValues));
    setIsSubmit(true);
  };
  const success = () =>
    toast.success("Message sent successfully!", {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const errormsg = () =>
    toast.error("Error !", {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    //console.log(errors);
    if (Object.keys(errors).length === 0 && isSubmit) {
      emailjs
        .sendForm(
          "service_js9rkwc",
          "template_rh5wdaj",
          form.current,
          "Op7pqeeiX8-JvYpCS"
        )
        .then(
          (result) => {
            success();
            //console.log(result);
          },
          (error) => {
            errormsg();
            // console.log(error.text);
          }
        );
      //console.log(formValues);
      setFormValues(initialValues);
    }
    // eslint-disable-next-line
  }, [errors]);
  // eslint-disable-next-line
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.user_email) {
      errors.user_email = "Email is required!";
    } else if (!regex.test(values.user_email)) {
      errors.user_email = "This is not a valid email format!";
    }
    if (!values.message) {
      errors.message = "Message is required!";
    }

    return errors;
  };
  return (
    <section
      className="section has-background-light is-medium"
      id="contact"
      data-aos="zoom-in"
    >
      <div className="content">
        <h1 className="is-size-1 has-text-centered has-text-dark underline">
          <span>Contact Us</span>
        </h1>
      </div>
      <div className="columns is-centered ">
        <div className="column is-half ">
          <form ref={form} onSubmit={sendEmail}>
            <div className="control has-icons-left has-icons-right">
              <input
                className={`input is-medium ${
                  errors.user_email ? "is-danger" : "is-success"
                }  `}
                placeholder="Email"
                name="user_email"
                onChange={handleChange}
                value={formValues.user_email}
              />
              <span className="icon is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </div>
            <p>{errors.user_email}</p>
            <ToastContainer
              className="mt-6"
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="field">
              <div className="control mt-4">
                <textarea
                  name="message"
                  className={`textarea is-medium ${
                    errors.message ? "is-danger" : "is-success"
                  }`}
                  placeholder="Message"
                  rows="7"
                  onChange={handleChange}
                  value={formValues.message}
                ></textarea>
              </div>
              <p>{errors.message}</p>
            </div>

            <div className="control buttons is-centered">
              <button className="button  is-medium submitbtn" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
