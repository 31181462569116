import React from "react";
import { FiMail } from "react-icons/fi";
import { BsTelephone, BsArrowUpSquare } from "react-icons/bs";
import { TbLocation } from "react-icons/tb";
import { Link } from "react-scroll";
const logo = require("./logo.png");

export default function Footer() {
  return (
    <div>
      <section className="hero is-small has-background-black-ter">
        <div className="hero-body">
          <br />
          <br />
          <div className="columns is-mobile is-centered has-text-grey-light is-multiline">
            <div className="column has-text-centered  is-two-fifths-mobile">
              <div className="content">
                <h6 className="subtitle is-6 has-text-grey-light">
                  USEFUL LINKS
                </h6>
                <a
                  className="has-text-grey-light link"
                  title="studentmag.tn"
                  href="https://studentmag.tn/society/haymos-the-success-of-three-tunisians-brothers/"
                >
                  Student Magazine
                </a>
                <br />
                <a
                title="leconomistemaghrebin"
                  className="has-text-grey-light link"
                  href="https://www.leconomistemaghrebin.com/2021/04/15/haymos-voiture-fabrique-localement-freres-tunisiens/"
                >
                  Econimiste Maghrebien
                </a>
                <br />
                <a
                  title="tekiano"
                  className="has-text-grey-light link"
                  href="https://www.tekiano.com/2021/04/20/trois-freres-tunisiens-fabriquent-une-voiture-a-partir-de-pieces-de-ferraille-video/"
                >
                  Tekiano
                </a>
              </div>
            </div>
            <div className="column has-text-centered is-two-fifths-mobile">
              <BsTelephone className="block" size={40} />
              <div className="content">
                <h6 className="subtitle is-6 has-text-grey-light">
                  <a
                    className="has-text-grey-light link"
                    href="tel:+21653830825"
                  >
                    {" "}
                    +216 53 830 825
                  </a>
                </h6>
              </div>
            </div>
            <div className="column has-text-centered has-text-grey-light is-two-fifths-mobile">
              <FiMail className="block" size={40} />
              <div className="content">
                <h6 className="subtitle is-6 has-text-grey-light">
                  <a
                    className="has-text-grey-light link"
                    href="mailto:khefifi.montassar@gmail.com"
                  >
                    {" "}
                    khefifi.montassar@gmail.com
                  </a>
                </h6>
              </div>
            </div>
            <div className="column has-text-centered has-text-grey-light is-two-fifths-mobile">
              <TbLocation className="block" size={40} />
              <div className="content">
                <h6 className="subtitle is-6 has-text-grey-light">
                  <a
                  title="nabeul"
                    className="has-text-grey-light link"
                    href="https://goo.gl/maps/Yfa7sm81y5UxJbqu6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Nabeul, Tunisia
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="has-background-black">
        <div className="columns  content has-text-centered has-text-grey-light">
          <div className="column pt-5">
            <p>COPYRIGHT &copy; 2022 HAYMOS TUNISIE</p>
          </div>

          <div className="column">
            <Link smooth spy to="home">
              <img src={logo} width={100} height={100} alt="Logo Haymos"></img>
            </Link>
          </div>
          <div className="column pt-5">
            <p>
              MADE WITH &#128154; BY{" "}
              <a style={{ color: "hsl(141, 95%, 49%)" }} target="_black" href="https://montassarkh.netlify.app/" title="Montassar khefifi">
                {" "}
                Montassar Khefifi
              </a>
              .
            </p>
            <div className="buttons is-right">
              <Link smooth spy to="home">
                <BsArrowUpSquare
                  style={{ margin: "7px" }}
                  size={45}
                  color={"hsl(0, 0%, 96%) "}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
