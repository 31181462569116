import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";


export default function Gallery() {
  const s1 = require("./images/img/haymos1.JPG");
  const s2 = require("./images/img/haymos7.jpg");
  const s3 = require("./images/img/haymos3.JPG");
  const s4 = require("./images/img/haymos4.JPG");
  const s5 = require("./images/img/haymos5.JPG");
  const s6 = require("./images/img/team.JPG");
  const h4 = require("./images/placeholderImg/placeholder4.JPG");
  const h1 = require("./images/placeholderImg/placeholder1.JPG");
  const h2 = require("./images/placeholderImg/placeholder7.jpg");
  const h3 = require("./images/placeholderImg/placeholder3.JPG");
  const h5 = require("./images/placeholderImg/placeholder5.JPG");
  const h6 = require("./images/placeholderImg/teamplc.JPG");
  const images = [
    [s1, h1],
    [s6, h6],
    [s3, h3],
    [s5, h5],
    [s2, h2],
    [s4, h4],
  ];

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = (srcImg) => {
    setShowModal(true);
    setImgModal(srcImg);
  };
  const [imgModal, setImgModal] = useState();

  return (
    <section className="section is-medium has-background-light" id="gallery">
      <div className="content">
        <h1 className="is-size-1 has-text-centered has-text-dark underline">
          <span>Gallery</span>
        </h1>
      </div>
      <div className="columns is-gapless is-multiline is-mobile">
        {images.map((s, index) => (
          <div className="column is-one-third" key={index} data-aos="flip-up">
            <LazyLoadImage
              className="imgEffect"
              src={s[0]}
              onClick={(event) => handleShow(s[0])}
              alt="buggy haymos "
              placeholderSrc={s[1]}
              effect="blur"
            />
          </div>
        ))}
      </div>
      <div className={`modal ${showModal ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <p className="image  is-square ">
            <img src={imgModal} alt="haymos tunisie" />
          </p>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={handleClose}
        ></button>
      </div>
    </section>
  );
}
